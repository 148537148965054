/**
 * Reveal - allows css animations when an element is scrolled into view.
 *
 * 	- Processes all elements with a class 'reveal-js'
 *  - If the element is already in the window's viewport, does nothing.
 *  - If the element is not yet in the viewport, adds a class of
 *    'reveal-js--hidden'.
 *  - The script then adds a debounceScroll listener waiting for the element to
 *    scroll into view.
 *  - When the element is scrolled into view, the 'reveal-js--hidden' class is
 *    removed. This makes css animations possible.
 *
 *    Elements with 'reveal-js--hidden' should be styled appropriately in css.
 *    Note that the hidden element should probably have the style
 *    'transition: none;', otherwise the delay and transition time will add to
 *    the time to hide the element, making it likely for the element to never
 *    be hid before being scrolled into view.
 *
 * @usage:
 *
 * 		Data attributes can be used to control various aspects
 * 		of the animation.
 *
 * 		data attributes:
 *
 * @data margin-bottom - Adds a margin to the bottom of the viewport in which the
 * 			element must be scrolled to be considered visible. (defaults to half
 * 			of elements height)
 * @data margin-top - Adds a margin to the top of the viewport in which the
 * 			element must be scrolled to be considered visible. (defaults to 0)
 *
 * DBS>Interactive
 * @author Harold Bradley III
 */


window.addEventListener( 'load', function() {
	jQuery( '.reveal-js' ).each( function () {

		var element = jQuery( this );
		var element_top = element.offset().top;
		var element_bottom = element_top + element.height();
		var window_height = $( window ).height();

		var margin_bottom = element.data( 'margin-bottom' ) == undefined ? element.height() / 2 : element.data( 'margin-bottom' );
		var margin_top = element.data( 'margin-top' ) == undefined ? 0 : element.data( 'margin-top' );

		/**
		 * Function to test whether an element is in viewport or not
		 *
		 * @param element - element to test
		 *
		 * @return boolean - true if element is in viewport
		 */
		function in_viewport( element ) {
			var viewport_top = $( window ).scrollTop() + margin_top;
			var viewport_bottom = window_height + $( window ).scrollTop() - margin_bottom;
			if ( viewport_top < element_top && element_bottom < viewport_bottom ) { return true; }
			return false;
		}


		// Reset element's top and bottom and window height at every resize.
		jQuery( window ).resize( function() {
			element_top = element.offset().top;
			element_bottom = element_top + element.height();
			window_height = $( window ).height();
		});

		// If it's already in view, do nothing.
		if ( in_viewport( element ) ) { return; }


		// Begin with the element hidden
		element.addClass('reveal-js--hidden');

		/**
		 * Scroll event handler
		 *
		 * Removes 'reveal-js--hidden' class when element is scrolled in view.
		 */
		window.addEventListener( 'debounceScroll', function() {
			if ( in_viewport( element ) ) { element.removeClass('reveal-js--hidden'); }
		}, false );

	});
});
